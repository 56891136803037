'use strict';

window.console || (window.console = {
	log: function log() {}
});
var NOK = window.NOK || null;
var cssua = window.cssua || null;

(function ($) {
	var siteName = '';
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '400';

	NOK = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test.' + siteName + '.co.jp|copre.jp|localhost|192.168.0.|192.168.11.|192.168.1.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var $includeComment = $('.container, .mod-spacer, .mod-sideNav-nav').contents().filter(function () {
				return this.nodeType === document.COMMENT_NODE;
			});
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var test = [];
			$includeComment.each(function (idx, ele) {
				if (ele.data.indexOf('#include') !== -1) {
					var filePath = ele.data.split('=')[1].replace(/("| )/g, '');
					ajaxArray.push(filePath);
				}
			});
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i],
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);
					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						document.body.innerHTML = document.body.innerHTML.replace('<!--#include virtual="/common/include/' + position + '.html" -->', result[0]);
						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.mod-toggle');

			if (NOK.va.pathname.indexOf('/csr/social/past_record.html') !== -1) {
				$switch.on('click', function () {
					if ($(this).hasClass('_sub')) {
						$('.mod-toggle._sub').removeClass(a);
						$('.mod-toggle._sub').next('.mod-toggleBlock').stop().slideUp(slideSpeed);
					}
					$(this).toggleClass(a);
					$(this).next('.mod-toggleBlock').stop().slideToggle(slideSpeed);
				});
			} else {
				$switch.on('click', function () {
					$(this).toggleClass(a);
					$(this).next('.mod-toggleBlock').stop().slideToggle(slideSpeed);
				});
			}
		},
		easeScroll: function easeScroll() {
			$('[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (NOK.va.window.width < NOK.va.device.sp) {
						pos -= 60;
					} else {
						pos -= 90;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			var speed = 1200;
			if (hash) {
				$(window).on('load', function () {
					$('html, body').css('display', 'none');

					setTimeout(function () {
						$('html, body').css('display', 'block');

						var _hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;

						if (NOK.va.window.width < NOK.va.device.sp) {
							pos -= 60;
						} else {
							pos -= 90;
						}
						$('html, body').stop().animate({ scrollTop: pos }, speed, 'easeOutExpo');
					}, 100);
				});
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				// var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				// var pagetopPos = $this.height() + $footer.height() + thisScroll;

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		headerMenu: function headerMenu() {
			var $body = $('body');
			var $map = NOK.va.pathname;
			var $header = $('.header');
			var $menuBtn = $('.header-menuBtn');
			var $headerMenu = $('.header-menu');
			var s = 'is-small';
			var f = 'is-fixed';

			// スクロールしたらヘッダー縮小
			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var thisScroll = $this.scrollTop();
				if (thisScroll > 120) {
					$header.addClass(s);
				} else {
					$header.removeClass(s);
				}
			});
			if ($(window).scrollTop() > 120) {
				$header.addClass(s);
			} else {
				$header.removeClass(s);
			}

			// ナビゲーションのカレント
			$('.header-menu-li-a').each(function (idx, ele) {
				if ($map.indexOf($(ele).attr('href')) !== -1) {
					$(ele).addClass(a);
				}
			});

			// ヘッダーメニュー開閉
			$menuBtn.on('click', function (e) {
				var scrTop = window.pageYOffset;
				var $this = $(e.currentTarget);
				if ($body.hasClass(f)) {
					$body.removeClass(f);
					window.scroll(0, $body.css('top').replace('-', '').replace('px', ''));
					$body.css('top', 'auto');
				} else {
					$body.addClass(f);
					$body.css('top', -scrTop);
				}
				$this.toggleClass(a);
				$headerMenu.toggleClass(a);
			});
			$headerMenu.on('click', function (e) {
				if (!$(e.target).closest('.header-menu-li-a').length) {
					if ($body.hasClass(f)) {
						$body.removeClass(f);
						$headerMenu.removeClass(a);
						$menuBtn.removeClass(a);
						window.scroll(0, $body.css('top').replace('-', '').replace('px', ''));
						$body.css('top', 'auto');
					}
				}
			});
		},
		headerMegaMene: function headerMegaMene() {
			var $btn = $('.header-menu-li-a');
			var $dropdown = $('.header-menu-dropdown');
			var $nav = $('.header-menu');
			var $hovBtn = $('.header-menu-dropdown-left-li');
			var $hovConts = $('.header-menu-dropdown-right');
			var $spMenu = $('.header-menu-dropdown-inner');

			var megamenuPc = function megamenuPc() {
				$('.header-menu-dropdown-inner').css('display', '');
				$btn.on('mouseenter', function (e) {
					$dropdown.removeClass(v);
					$(e.target).parent('.header-menu-li-inner').next($dropdown).addClass(v);
					$hovBtn.find('.mod-a').removeClass(a);
					$hovConts.removeClass(a);
					$(e.target).parent('.header-menu-li-inner').next($dropdown).find($hovBtn).find('.mod-a').eq(0).addClass('is-active');
					$(e.target).parent('.header-menu-li-inner').next($dropdown).find($hovConts).eq(0).addClass('is-active');
				});
				$nav.on('mouseleave', function () {
					$dropdown.removeClass(v);
				});
				$('.header-menu-inner').on('mouseover', function (e) {
					if (!$(e.target).closest('.header-menu-li-inner, .header-menu-dropdown').length) {
						$dropdown.removeClass(v);
					}
				});
				$dropdown.on('mouseover', function (e) {
					if (!$(e.target).closest('.header-menu-dropdown-inner').length) {
						$dropdown.removeClass(v);
					}
				});

				// ドロップダウン内の内容のホバー切り替え
				$hovBtn.find('.mod-a').on('mouseover', function (e) {
					var thisCat = $(e.target).attr('data-cat');
					$hovBtn.find('.mod-a').removeClass(a);
					$hovConts.removeClass(a);
					$(e.target).addClass(a);
					$(e.target).closest($dropdown).find($hovConts).each(function (idx, ele) {
						if ($(ele).attr('data-cat') === thisCat) {
							$(ele).addClass(a);
						}
					});
				});

				// 要素の高さ
				var maxHeight = function maxHeight() {
					$dropdown.each(function (idx, ele) {
						var arr = [];
						$(ele).find($hovConts).each(function (idx2, ele2) {
							arr.push($(ele2).height());
						});
						var max = arr.reduce(function (a, b) {
							return Math.max(a, b);
						});
						$(ele).find($hovConts).css('height', max);
					});
				};
			};

			var widthFlag = '';
			$(window).on('load resize', function () {
				widthCheck();
			});
			widthCheck = function widthCheck() {
				var winWidth = $(window).width();
				if (winWidth <= 1024 && widthFlag != 'sp') {
					// SP処理
					widthFlag = 'sp';
					$btn.off('click');
					$btn.on('click', function (e) {
						if (!$(e.target).hasClass('header-menu-li-news-a') && !$(e.target).hasClass('header-menu-li-contact-a')) {
							$(e.target).toggleClass(v);
							$(e.target).parent('.header-menu-li-inner').next($dropdown).children($spMenu).slideToggle();
							e.preventDefault();
						}
					});
				} else if (winWidth > 1024 && widthFlag != 'pc') {
					// PC処理
					widthFlag = 'pc';
					$btn.off('click');
					megamenuPc();
				}
			};
		},
		sideNav: function sideNav() {
			var $menuItem = $('a.mod-sideNav-nav-list-a');
			var $menuBtn = $('span.mod-sideNav-nav-list-a');
			var $subMenu = $('.mod-sideNav-nav-list-sub');
			var $subMenuItem = $('.mod-sideNav-nav-list-sub-a');
			var $subMenuBtn = $('span.mod-sideNav-nav-list-sub-a');

			// サブメニュー開閉
			if ($subMenu.length) {
				$subMenu.not('.is-active').hide();
				$menuBtn.on('click', function (e) {
					$(e.currentTarget).toggleClass(a);
					$(e.currentTarget).next($subMenu).slideToggle();
				});
			}
			// サブメニュー内のサブメニュー開閉
			if ($subMenuBtn.length) {
				$subMenuBtn.on('click', function (e) {
					$(e.currentTarget).toggleClass(a);
					$(e.currentTarget).next($subMenu).slideToggle();
				});
			}

			// カレント
			if ($menuItem.length) {
				$menuItem.each(function (idx, ele) {
					if (NOK.va.pathname.indexOf($(ele).attr('href')) !== -1) {
						$(ele).addClass(a);
						$(ele).next($subMenu).css('display', 'block');
						$(ele).next($subMenu).css('display', 'block');
					}
				});
			}

			if ($subMenuItem.length) {
				$subMenuItem.each(function (idx, ele) {
					if (NOK.va.pathname === $(ele).attr('href') || NOK.va.pathname === $(ele).attr('href') + 'index.html') {
						$(ele).addClass(a);
						$(ele).parents('.mod-sideNav-nav-list-sub').prev('.mod-sideNav-nav-list-a').addClass(a);
						$(ele).parents('.mod-sideNav-nav-list-sub').css('display', 'block');
						$(ele).parents('.mod-sideNav-nav-list-sub').prev('.mod-sideNav-nav-list-sub-a').addClass(a);
					}
				});
			}
		},
		modSlider: function modSlider() {
			// 共通スライダー
			var arrowPrev = '<button type="button" class="slick-prev"><i class="icon icon-arrow_left"></button>';
			var arrowNext = '<button type="button" class="slick-next"><i class="icon icon-arrow_right"></button>';
			$('.mod-contsSlider01').slick({
				infinite: true,
				speed: 1500,
				autoplay: true,
				autoplaySpeed: 2500,
				slidesToShow: 3,
				arrows: true,
				dots: true,
				prevArrow: arrowPrev,
				nextArrow: arrowNext,
				responsive: [{
					breakpoint: 768,
					settings: {
						slidesToShow: 1
					}
				}]
			});
		},
		tabChange: function tabChange() {
			if ($('.mod-tab').length) {
				if ($('.mod-tab').eq(0).hasClass('js-tabChange')) {
					var $hash = location.hash;
					var path = $(location).attr('pathname');
					if ($hash.length) {
						$('.mod-tab-btn').each(function (idx, ele) {
							if ($(ele).attr('data-name') === $hash.replace('#', '')) {
								$(ele).addClass(a);
								$('.mod-tab-content').eq(idx).addClass(a);
							}
						});
					} else {
						$('.mod-tab-content').eq(0).addClass(a);
						$('.mod-tab-btn').eq(0).addClass(a);
					}
					$('.mod-tab-btn').each(function (idx, val) {
						$(val).on('click', function () {
							$('.mod-tab-btn').removeClass(a);
							$(val).addClass(a);
							$('.mod-tab-content').removeClass(a);
							$('.mod-tab-content').eq(idx).addClass(a);
						});
					});
				}
				// SPアコーディオン開閉
				$('.mod-tab-head_sp').each(function (idx, val) {
					$(val).on('click', function (e) {
						$(e.currentTarget).toggleClass(a);
						$(e.currentTarget).next('.mod-tab-btnList').stop().slideToggle(slideSpeed);
					});
				});
			}
		},
		anchorToggle: function anchorToggle() {
			var $anchorHead = $('.mod-anchor-head');

			$anchorHead.on('click', function (e) {
				$anchorHead.toggleClass(a);
				$(e.target).next('.mod-anchor-body').stop().slideToggle(slideSpeed);
			});
		},
		removeElmIfEmpty: function removeElmIfEmpty() {
			$('.js-checkEmptyElm').each(function (idx, val) {
				if (!$(val).text().length) {
					$(val).remove();
				}
			});
		},
		removeBlockIfListEmpty: function removeBlockIfListEmpty() {
			if ($('.js-checkEmptyList').length) {
				$('.js-checkEmptyList').each(function (idx, val) {
					if (!$(val).find('li').length) {
						$(val).parents('.js-checkEmptyList-wrap').remove();
					}
				});
			}
		},
		modal: function modal() {
			$('.mod-modal').on('click', function (e) {
				var HTML = '<div class="mod-modal-overlay">';
				HTML += '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><span></span></span>' + ($(e.currentTarget).find('img').prop('outerHTML') + '</div>');
				$('body').append(HTML);
				var $overlay = $('.mod-modal-overlay');
				$overlay.addClass(a);
				setTimeout(function () {
					$overlay.addClass(v);
					$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
						$overlay.removeClass(v);
						setTimeout(function () {
							$overlay.removeClass(a);
							$overlay.remove();
						}, 400);
					});
				});
			});
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.headerMenu();
			_this.headerMegaMene();
			_this.sideNav();
			_this.modSlider();
			_this.tabChange();
			_this.anchorToggle();
			_this.removeElmIfEmpty();
			_this.removeBlockIfListEmpty();
			_this.modal();
		}
	};

	$(function () {
		return NOK.localDecision() ? NOK.localLoading() : NOK.loadDelayScript();
	});
})(window.jQuery);